import { ReactNode } from 'react';
import { Typography, Stack } from '@mui/material';
import {
  AttachFile as FileIcon,
  BeachAccess as BeachAccessIcon,
  Cloud as CloudIcon,
  DeviceHub as DeviceHubIcon,
  Event as EventIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import styled from 'styled-components';

import {
  AssetFileRevisionCreated,
  Class,
  ConfigurationCreated,
  ConfigurationDemoted,
  ConfigurationPromoted,
  CouponClass,
  EventsMapperCreated,
  Notification,
  NotificationWithAppStruct,
  NotificationWithCloudFunctionStruct,
  NotificationWithCouponStruct,
  NotificationWithExperimentStruct,
  NotificationWithFileRevisionStruct,
  NotificationWithFileStruct,
  NotificationWithFlowStruct,
  NotificationWithGameEventStruct,
  NotificationWithIntegrationStruct,
  NotificationWithItemStruct,
  NotificationWithLeaderboardStruct,
  NotificationWithMatchStruct,
  NotificationWithPackageStruct,
  NotificationWithSpaceStruct,
  NotificationWithSynapseProblemStruct,
  NotificationWithTeamTemplateStruct,
  NotificationWithWebEventStruct,
  SegmentationCreated,
  SegmentationDemoted,
  SegmentationPromoted,
  TraitsCreated,
} from '@playq/octopus-notifications';
import { App } from '@playq/octopus2-apps';

import { ActionMessage } from './ActionMessage';
import { AddIconStyled, EditIconStyled } from './styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const getMessage = (n: Notification, app?: App, hideEntityDetails?: boolean): ReactNode => {
  if (!app) {
    return n.message;
  }
  switch (n.class_) {
    case Class.Apps: {
      const appsNotification = n as NotificationWithAppStruct;
      return <ActionMessage notification={n} title={appsNotification.appName} />;
    }
    case Class.Integrations: {
      const integrationNotification = n as NotificationWithIntegrationStruct;
      const title = hideEntityDetails
        ? `Integration`
        : `${integrationNotification.integrationName}(${integrationNotification.integrationProvider})`;
      return <ActionMessage notification={n} StartIcon={DeviceHubIcon} title={title} />;
    }
    case Class.WebEvents: {
      const webEventNotification = n as NotificationWithWebEventStruct;
      const title = hideEntityDetails
        ? `Web event`
        : `${webEventNotification.webEventName}(${webEventNotification.webEventDestination})`;
      const link = hideEntityDetails ? undefined : `/corp/webevents/${webEventNotification.webEventID}`;
      return <ActionMessage notification={webEventNotification} StartIcon={SendIcon} title={title} link={link} />;
    }
    case Class.Space: {
      return (
        <ActionMessage
          notification={n}
          StartIcon={BeachAccessIcon}
          title={(n as NotificationWithSpaceStruct).spaceName}
        />
      );
    }
    case Class.Configuration: {
      if (n instanceof ConfigurationCreated) {
        const title = n.version === 1 ? 'AppConfig' : `AppConfig v.${n.version}`;
        const Icon = n.version === 1 ? AddIconStyled : EditIconStyled;
        return (
          <Stack spacing={1} direction='row'>
            {title}
            <Icon fontSize='small' />
          </Stack>
        );
      }
      if (n instanceof ConfigurationPromoted || n instanceof ConfigurationDemoted) {
        return <ActionMessage notification={n} title={`AppConfig v.${n.version}`} />;
      }
      return n.message;
    }
    case Class.EconomyInventory: {
      const itemNotification = n as NotificationWithItemStruct;
      const appIdFromItemID = app.fingerprintID.app;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/economy/inventory/${appIdFromItemID}/${itemNotification.itemID}`;
      return <ActionMessage notification={itemNotification} title={`Item: ${itemNotification.itemName}`} link={link} />;
    }
    case Class.EconomyPackages: {
      const packageNotification = n as NotificationWithPackageStruct;
      const title = hideEntityDetails ? `Package` : `Package: ${packageNotification.packageName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/economy/packages/${packageNotification.packageID}`;
      return <ActionMessage notification={packageNotification} title={title} link={link} />;
    }
    case Class.EconomyCoupons: {
      const couponNotification = n as NotificationWithCouponStruct;
      const title = hideEntityDetails ? 'Coupon' : `Coupon: ${couponNotification.couponName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/economy/coupons/${
            couponNotification.couponClass === CouponClass.Mass ? 'mass' : 'target'
          }/${couponNotification.couponID}`;
      return <ActionMessage notification={couponNotification} title={title} link={link} />;
    }
    case Class.AnalyticsTraits: {
      if (n instanceof TraitsCreated) {
        const title = n.version === 1 ? 'Traits' : `Traits v.${n.version}`;
        const Icon = n.version === 1 ? AddIconStyled : EditIconStyled;
        return (
          <Stack direction='row' spacing={1}>
            {title}
            <Icon fontSize='small' />
          </Stack>
        );
      }
      return n.message;
    }
    case Class.AnalyticsExperiments: {
      const experimentNotification = n as NotificationWithExperimentStruct;
      const title = hideEntityDetails ? `Experiment` : `Exp.: ${experimentNotification.experimentName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/analytics/experiments/${experimentNotification.experimentID}`;
      return <ActionMessage notification={experimentNotification} title={title} link={link} />;
    }
    case Class.AnalyticsEventsMapper: {
      if (n instanceof EventsMapperCreated) {
        const title = n.version === 1 ? 'Events mapper' : `Events mapper v.${n.version}`;
        const Icon = n.version === 1 ? AddIconStyled : EditIconStyled;
        return (
          <Stack direction='row' spacing={1}>
            {title}
            <Icon fontSize='small' />
          </Stack>
        );
      }
      return n.message;
    }
    case Class.AnalyticsSegmentation: {
      if (n instanceof SegmentationCreated) {
        const title = n.version === 1 ? 'Segmentation' : `Segmentation v.${n.version}`;
        const Icon = n.version === 1 ? AddIconStyled : EditIconStyled;
        return (
          <Stack direction='row' spacing={1}>
            {title}
            <Icon fontSize='small' />
          </Stack>
        );
      }
      if (n instanceof SegmentationPromoted || n instanceof SegmentationDemoted) {
        return <ActionMessage notification={n} title={`Segmentation v.${n.version}`} />;
      }
      return n.message;
    }
    case Class.SocialLeaderboards: {
      const leaderboardNotification = n as NotificationWithLeaderboardStruct;
      const title = hideEntityDetails ? 'Leaderboard' : `LBoard: ${leaderboardNotification.leaderboardName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/social/leaderboards/${leaderboardNotification.leaderboardID}`;
      return <ActionMessage notification={leaderboardNotification} title={title} link={link} />;
    }
    case Class.SocialTeams: {
      const teamNotification = n as NotificationWithTeamTemplateStruct;
      const title = hideEntityDetails ? `Team` : `Team:${teamNotification.teamTemplateName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/social/teams/${teamNotification.teamTemplateID}`;
      return <ActionMessage notification={teamNotification} title={title} link={link} />;
    }
    case Class.SocialMatches: {
      const matchNotification = n as NotificationWithMatchStruct;
      const title = hideEntityDetails ? 'Match' : `Match: ${matchNotification.matchName}`;
      const link = hideEntityDetails ? undefined : `/apps/${app.routeName}/social/matches/${matchNotification.matchID}`;
      return <ActionMessage notification={matchNotification} title={title} link={link} />;
    }
    case Class.Files: {
      const className = n.getFullClassName();
      if (
        NotificationWithFileStruct.isRegisteredType(className) ||
        NotificationWithFileRevisionStruct.isRegisteredType(className)
      ) {
        const fileNotification = n as NotificationWithFileStruct | NotificationWithFileRevisionStruct;
        const isFileRevision = NotificationWithFileRevisionStruct.isRegisteredType(className);
        const title = isFileRevision ? `(rev) ${fileNotification.fileName}` : fileNotification.fileName;
        return (
          <ActionMessage
            notification={n}
            StartIcon={FileIcon}
            title={title}
            link={`/files/${fileNotification.fileID}`}
          />
        );
      }
      return n.message;
    }
    case Class.CreativeAssets: {
      const creativeAssetsNotification = n as NotificationWithFileRevisionStruct;
      const isAssetRevision = creativeAssetsNotification instanceof AssetFileRevisionCreated;
      const title = `Asset file ${isAssetRevision ? `(rev)` : ``}: ${creativeAssetsNotification.fileName}`;
      return (
        <ActionMessage
          notification={creativeAssetsNotification}
          title={title}
          link={`/apps/${app.routeName}/ua/assets/${creativeAssetsNotification.fileID}`}
        />
      );
    }
    case Class.LiveOpsGameEvents: {
      const gameEventNotification = n as NotificationWithGameEventStruct;
      const title = hideEntityDetails ? `Game event` : gameEventNotification.gameEventName;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/liveops/events/${gameEventNotification.gameEventID}`;
      return <ActionMessage notification={gameEventNotification} StartIcon={EventIcon} title={title} link={link} />;
    }
    case Class.LiveOpsFlow: {
      const flowNotification = n as NotificationWithFlowStruct;
      const title = hideEntityDetails ? 'Flow' : `Flow: ${flowNotification.flowName}`;
      const link = hideEntityDetails ? undefined : `/apps/${app.routeName}/liveops/flows/${flowNotification.flowID}`;
      return <ActionMessage notification={flowNotification} title={title} link={link} />;
    }
    case Class.MachineLearningSynapse: {
      const synapseNotification = n as NotificationWithSynapseProblemStruct;
      const title = hideEntityDetails ? 'Synapse' : `Synapse: ${synapseNotification.problemName}`;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/ml/synapse/problems/${synapseNotification.problemID}`;
      return <ActionMessage notification={synapseNotification} title={title} link={link} />;
    }
    case Class.CloudFunctions: {
      const cloudFunctionNotification = n as NotificationWithCloudFunctionStruct;
      const title = hideEntityDetails ? 'Cloud Function' : cloudFunctionNotification.cloudFunctionName;
      const link = hideEntityDetails
        ? undefined
        : `/apps/${app.routeName}/services/cloud-functions/${cloudFunctionNotification.cloudFunctionID}`;
      return <ActionMessage notification={cloudFunctionNotification} title={title} link={link} StartIcon={CloudIcon} />;
    }
    default:
      return (
        <Typography noWrap={true} variant='inherit'>
          {n.message}
        </Typography>
      );
  }
};
