import styled from 'styled-components';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1)};
  height: 100%;
`;

export const Items = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex-direction: column;
  height: 430px;
  width: 760px;
`;

export const SubmitButton = styled(Button)`
  display: block;
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-left: auto;
`;
