import styled, { css } from 'styled-components';
import { Checkbox, alpha, Select } from '@mui/material';

export const FilterTab = styled.div<{ active: boolean; selected: boolean; mainColor: string }>`
  z-index: 0;
  position: relative;
  padding: 2px;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid transparent;
  border-bottom: none;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;

  &:first-of-type {
    margin-left: ${(props) => props.theme.spacing(1)};
  }

  ${(props) =>
    props.selected &&
    css`
      z-index: 2;
      border-color: ${props.theme.palette.divider} ${props.theme.palette.divider} transparent;
      &:before {
        content: '';
        width: calc(100% + 16px);
        height: 10px;
        bottom: -3px;
        left: -8px;
        position: absolute;
        background: radial-gradient(
            circle at top left,
            transparent 6px,
            ${props.theme.palette.divider},
            ${props.theme.palette.background.paper} 8px,
            transparent
          ),
          radial-gradient(
            circle at top right,
            transparent 6px,
            ${props.theme.palette.divider},
            ${props.theme.palette.background.paper} 8px,
            transparent
          );
      }
    `}

  ${(props) =>
    props.active &&
    css`
      .MuiIconButton-root {
        color: ${props.mainColor};
        &:hover {
          background-color: ${alpha(props.mainColor, props.theme.palette.action.hoverOpacity)};
        }
      }
    `}
`;

export const FilterContent = styled.div<{ $expanded?: boolean }>`
  z-index: 1;
  min-height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(2)};
  padding: 0 ${(props) => props.theme.spacing(1)};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-left: none;
  border-right: none;

  ${(props) =>
    props.$expanded &&
    css`
      min-height: unset;
      gap: ${props.theme.spacing(2)};
      margin: 0;
      padding: ${props.theme.spacing(2)};
    `}
`;

export const FilterColumn = styled.div`
  flex: 1;
`;

export const DateFilterColumn = styled(FilterColumn)`
  width: 330px;
  flex: unset;
`;

export const AuthorFilterColumn = styled(FilterColumn)`
  .MuiOutlinedInput-root {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;

    .MuiAutocomplete-input:first-child {
      padding: 14px;
      padding-left: 0;
    }
  }
`;

export const RightActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${(props) => props.theme.spacing(1)};
`;

export const EnvSpaceWrapper = styled.div`
  align-self: stretch;
  padding-top: 2px;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0;
  margin: 0 9px;
`;

export const GroupByFilterColumn = styled(FilterColumn)<{ $expanded?: boolean }>`
  max-width: ${(props) => (props.$expanded ? '200px' : 'unset')};
`;

export const SelectStyled = styled(Select)`
  min-width: 150px;

  .MuiSelect-iconOutlined {
    right: 0px;
  }
`;
