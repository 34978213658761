import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import styled from 'styled-components';

import { history } from '/Router/history';

const Wrapper = styled.div`
  color: #888;
  display: table;
  font-family: sans-serif;
  height: 100%;
  text-align: center;
  width: 100%;
`;

const Container = styled.div`
  display: table-cell;
  vertical-align: middle;
  margin: 2em auto;
  line-height: 1.4375;
`;

const Title = styled.h1`
  color: #555;
  font-size: 2em;
  font-weight: 400;
`;

const Content = styled.p`
  margin: 0 auto;
  width: 280px;
  font-size: 1.2em;
  &:first-of-type {
    margin-bottom: 10px;
  }
  & a {
    color: #333;
    &:hover {
      color: #e81c4f;
    }
  }
`;

const Code = styled.code`
  font-size: 1.2em;
`;

const handleHistoryBack = () => history.goBack();

export const NotFound: FC<{ location: Location }> = ({ location }) => (
  <Wrapper>
    <Container>
      <Title>Page Not Found</Title>
      <Code>{location.pathname}</Code>
      <Content>Sorry, but the page you were trying to view does not exist.</Content>
      <Content>
        Please, go to 👉
        <Link href='/'>home page</Link>
      </Content>
      <Content>
        Or back to 👉
        <RouterLink to='' onClick={handleHistoryBack}>
          previous page
        </RouterLink>
      </Content>
    </Container>
  </Wrapper>
);
