import { Theme, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const headerHeight = 48;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    height: headerHeight,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 5px',
    overflowX: 'auto',
  },
  appBarControlled: {
    backgroundColor: 'black',
  },
  appInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  divider: {
    height: '38px',
    margin: '0 4px',
    backgroundColor: 'white',
  },
  controlled: {
    lineHeight: '14px',
    fontSize: 13,
    alignSelf: 'center',
  },
  appName: {
    marginLeft: 5,
  },
  appIconWrapper: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const LinkToHome = styled(Link)`
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;
